<template>
  <main id="NotFound">
    <div class="row">
      <div class="content">
        <h3>404</h3>
        <h4>Ooops!!</h4>
        <p>您拜訪的頁面已經不存在或是目前無法使用</p>
      </div>
    </div>
  </main>
</template>

<script>
import { GetMetaData } from '@/common/meta.js';
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
export default {
  name: 'ErrorPage',
  mixins: [SmoothScrollResister],
  data() {
    return {
      meta_data: null,
    };
  },
  metaInfo() {
    return this.meta_data;
  },
  mounted() {
    this.RegisterGsap('NotFound');
  },
  created() {
    let meta_list = document.getElementsByTagName('meta');
    let meta_exist = false;
    for (let i = 0; i < meta_list.length; i++) {
      meta_list[i].name == 'prerender-status-code' &&
      meta_list[i].content == '404'
        ? (meta_exist = true)
        : '';
    }
    if (!meta_exist) {
      var meta = document.createElement('meta');
      meta.setAttribute('name', 'prerender-status-code');
      meta.content = '404';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
    this.meta_data = GetMetaData(
      '頁面不存在',
      '您所查詢的頁面不存在或目前無法存取，您可以回到首頁或前往其他頁面。',
      ''
    );
    this.$nextTick(() => {
      window.prerenderReady = true;
    });
  },
};
</script>
